import React from "react";

function Home() {
  return (
    <div className="home">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-4">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src="/stl.jpg"
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light">Experience the USA</h1>
            <p>
            The USA is one big country – and that means it’s absolutely jam-packed with bucket list experiences that are distinctly American. 
            From spectacular national parks and road trips, to iconic cities, sporting events and the best food and wine.
            </p>
            <p>
            Few countries pack in such diverse landscapes and experiences as the USA. Where else could you drive down some of the world's most famous highways, hike through red-rock canyons and some of the world famous Tourist Attractions in USA.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;