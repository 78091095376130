import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';


class FetchDemo extends React.Component {
  state = {
    posts: []
  }

  componentDidMount() {
    //axios.get(`http://www.reddit.com/r/${this.props.subreddit}.json`)
    axios.get(`http://localhost:4000/visitors/bycountry`)
      .then(res => {
        console.log(res);
        console.log("*******************************************************");
        const posts = res.data;//.children.map(obj => obj.data);
        console.log(posts);
        this.setState({ posts });
      });
  }

  render() {
    return (
      <ul>
        {this.state.posts.map(function(post, index){
          return (
              <div key={index}>
                <li>{post.Country} - {post.Student}</li>
              </div>
            )
          }
        )}
      </ul>
    )

  }

}

function Contact() {
  return (
    <div className="contact">
      <FetchDemo subreddit="reactjs"/>
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src="http://placehold.it/900x400"
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light">Contact</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;