import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Home, Bookmark, User } from "react-feather";
import styled, { createGlobalStyle } from "styled-components";

function NavigationMobile(props) {
  return (
    <MobileNavbar.Wrapper>
    <MobileNavbar.Items>
      <MobileNavbar.Item>
        <MobileNavbar.Icon>
          <Home size={16} />
        </MobileNavbar.Icon>
        Home
      </MobileNavbar.Item>
      <MobileNavbar.Item>
        <MobileNavbar.Icon>
          <Bookmark size={16} />
        </MobileNavbar.Icon>
        Blog
      </MobileNavbar.Item>
      <MobileNavbar.Item>
        <MobileNavbar.Icon>
          <User size={16} />
        </MobileNavbar.Icon>
        About
      </MobileNavbar.Item>
    </MobileNavbar.Items>
  </MobileNavbar.Wrapper>
  );
  const Navbar = {
    Wrapper: styled.nav`
      flex: 1;
  
      align-self: flex-start;
  
      padding: 1rem 3rem;
  
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      background-color: white;
    `,
    Logo: styled.h1`
      border: 1px solid gray;
      padding: 0.5rem 1rem;
    `,
    Items: styled.ul`
      display: flex;
      list-style: none;
    `,
    Item: styled.li`
      padding: 0 1rem;
      cursor: pointer;
    `
  };
  const MobileNavbar = {
    Wrapper: styled(Navbar.Wrapper)`
      align-self: flex-end;
  
      justify-content: center;
    `,
    Items: styled(Navbar.Items)`
      flex: 1;
      padding: 0 2rem;
  
      justify-content: space-around;
    `,
    Item: styled(Navbar.Item)`
      display: flex;
      flex-direction: column;
      align-items: center;
  
      font-size: 1.2rem;
    `,
    Icon: styled.span``,
  };
}

export default withRouter(NavigationMobile);