import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";

import Table from "../Table";
import "../css/App.css";

import databycountry from "../../data/bycountry.json"

const numberWithoutCommas = (x) => {
  var strNumber = Number(x.toString().replace(",", ""));
  return isFinite(strNumber) ? Number(strNumber) : 0;
};

var isNumber = function isNumber(value) 
{
   return typeof value === 'number' && isFinite(value);
}

function App() {
  const columns = useMemo(
    () => [
      {
        Header: "Please use the Search box to filter tourist number by country",
        columns: [
          {
            Header: "Country",
            accessor: "Country"
          },
          {
            Header: "Number of Toursist",
            accessor: d => numberWithoutCommas(d.TourismVisaWaiver) + numberWithoutCommas(d.TourismOther),
            sortMethod: (a, b) => Number(a)-Number(b)
          }
        ]
      }
    ],
    []
  );

  const [data, setData] = useState([]);
 

  useEffect(() => {setData(databycountry);
    //(async () => {
     // const result = "bycountry.json";//await axios("http://localhost:4000/visitors/bycountry");
      //.setData(result.data);
    //})();

  }, []);

  return (
    <div className="App">
      <h1>Number of Tourist Visted USA in 2021*</h1>
      
      <Table columns={columns} data={data} />
      <br/>
      <p><span> *2021 year refer to fiscal year, which run from October 1 2020 to September 30 2021</span>
        <br/>
        This data is based on number of nonimmigrant visa's/I-94's data and do not describe all nonimmigrant admissions because certain visitors are not required to fill all the details.
        <br/>
        <span>Please use this for educational purpose. Please refer to the actual Depart of Homeland Security for accurate Information.</span>
      </p>
    </div>
  );
}

export default App;