import React from "react";

function Footer() {
  return (
    <div className="footer">
      
      <footer class="py-5 bg-dark">
        <div class="container">
        <p class=" text-center text-white">This website indexes disclosure data from the the Department of Homeland Security (DHS).
</p>
          <p class="m-0 text-center text-white">
            Copyright &copy; visitorsinfo.us 2023
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;